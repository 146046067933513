<template>
  <div class="branding">
    <router-link :to="{ name: 'home' }" class="no-active" aria-label="Home">
      <img src="../../../assets/Logo-App.svg" alt="Logo Allez! Allez!" />
    </router-link>
  </div>
</template>

<style scoped>
@import './Navbar.scss';
</style>
