import { defineStore } from 'pinia'
import { type User as FirebaseUser } from 'firebase/auth'
import { type User as DbUser } from '@/types/Types'
import authService from '@/stores/auth'
import { getAdditionnalUserInfo } from '@/stores/users'

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    firebaseUser: null,
    dbUser: null
  }),
  persist: true,

  getters: {
    isAuthenticated: (state) => !!state.firebaseUser
  },

  actions: {
    getSignedInUser: async (state: AuthState) => {
      state.firebaseUser = await authService.getSignedInUser()

      if (state.firebaseUser) {
        state.dbUser = await getAdditionnalUserInfo(state.firebaseUser.uid)
      }
    },
    updateDbUser: async (state: AuthState) => {
      if (state.firebaseUser) {
        state.dbUser = await getAdditionnalUserInfo(state.firebaseUser.uid)
      }
    }
  }
})

interface AuthState {
  firebaseUser: FirebaseUser | null
  dbUser: DbUser | null
}
