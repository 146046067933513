export default [
  {
    path: '/admin',
    name: 'admin',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
    children: [
      {
        path: 'packs',
        children: [
          {
            path: '',
            name: 'packs',
            component: () => import('@/views/Admin/Packs/Admin.Packs.vue')
          },
          {
            path: ':id',
            name: 'pack',
            component: () => import('@/views/Admin/Packs/Admin.Pack.vue')
          },
          {
            path: 'challenges',
            name: 'challenges',
            component: () => import('@/views/Admin/Packs/Admin.Challenges.vue')
          },
          {
            path: 'categories',
            name: 'categories',
            component: () => import('@/views/Admin/Packs/categories/Admin.Categories.vue')
          },
          {
            path: 'tags',
            name: 'tags',
            component: () => import('@/views/Admin/Packs/tags/Admin.Tags.vue')
          }
        ]
      },
      {
        path: 'feedback',
        name: 'feedback',
        component: () => import('@/views/Admin/Feedback/Admin.Feedback.vue')
      },
      {
        path: 'add-admin',
        name: 'add-admin',
        component: () => import('@/views/Admin/Users/AddAdmin.vue')
      }
    ]
  }
]
