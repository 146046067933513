export default [
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/ErrorPages/404.not-found.vue')
  },
  {
    path: '/404',
    children: [
      {
        path: '',
        name: 'not-found',
        component: () => import('@/views/ErrorPages/404.not-found.vue')
      },
      {
        path: 'game',
        name: 'not-found-game',
        component: () => import('@/views/ErrorPages/404.not-found.game.vue')
      }
    ]
  },
  {
    path: '/403',
    children: [
      {
        path: '',
        name: 'forbidden',
        component: () => import('@/views/ErrorPages/403.forbidden.vue')
      },
      {
        path: 'forbidden-game',
        name: 'forbidden-game',
        component: () => import('@/views/ErrorPages/403.forbidden.game.vue')
      }
    ]
  }
]
