<template>
  <div class="switch-wrapper">
    <label class="switch" ref="toggler">
      <input type="checkbox" ref="input" :checked="modelValue" @click="handleClick" />
      <div class="slider round" :class="{ disabled: disabled }"></div>
    </label>
    <label v-if="title" :for="id" class="switch-label">{{ title }}</label>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  id: {
    type: String,
    required: true
  },
  modelValue: {
    type: Boolean,
    required: true
  },
  title: {
    type: String,
    required: false
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void
}>()

function handleClick(e: Event) {
  if (props.disabled) {
    e.preventDefault()
    return
  }

  emit('update:modelValue', !props.modelValue)
}
</script>

<style lang="scss" scoped>
@import './Switch.scss';
</style>
