import type { AcceptedCookies } from '@/types/Types'
import { createApp } from 'vue'
import app from '@/main'
import App from '@/App.vue'
import router from '@/router'
import VueGtag from 'vue-gtag'

export function applyCookies(acceptedCookies: AcceptedCookies) {
  try {
    const acceptedCookiesString = JSON.stringify(acceptedCookies)
    localStorage.setItem('acceptedCookies', acceptedCookiesString)
  } catch (error) {
    console.error('Error storing accepted cookies:', error)
  }
}

export function getAcceptedCookies(): AcceptedCookies | null {
  try {
    const acceptedCookiesString = localStorage.getItem('acceptedCookies')
    if (acceptedCookiesString) {
      return JSON.parse(acceptedCookiesString) as AcceptedCookies
    }
    return null
  } catch (error) {
    console.error('Error retrieving accepted cookies:', error)
    return null
  }
}

export function applyCookiesToGtag(acceptedCookies: AcceptedCookies) {
  try {
    const gtagEnabled = acceptedCookies?.analythics // Check if analytics cookies are accepted
    const gtagConfig = {
      appName: 'Allez Allez',
      enabled: gtagEnabled,
      pageTrackerScreenviewEnabled: true,
      config: { id: 'G-G369T2BFP0' }
    }

    // Get the current VueGtag instance
    const gtag = app.config.globalProperties?.$gtag

    // If VueGtag is already installed, update its configuration
    if (gtag) {
      gtag.set(gtagConfig)
    } else {
      // If VueGtag is not installed, create a new Vue app with VueGtag plugin enabled/disabled based on accepted cookies
      const newApp = createApp(App)
      if (gtagEnabled) {
        newApp.use(VueGtag, gtagConfig, router)
      } else {
        // If analytics cookies are not accepted, VueGtag will not be used
      }
      newApp.use(router)
      newApp.mount('#app')
    }
  } catch (error) {
    console.error('Error applying cookies to gtag:', error)
  }
}
