<template>
  <div class="category-toggle-container">
    <img :src="getCategoryImageFromStringAndAvatar(category, avatarType)" alt="" />
    {{ getCategoryTypeFromString(category, locale) }}
    <Switch
      :id="category"
      :modelValue="isCategoryToggled"
      @update:modelValue="toggleCategory"
      :disabled="disabled"
    />
  </div>
</template>

<script setup lang="ts">
import { AvatarTypes } from '@/types/Enums'
import Switch from '@/components/common/form/switch/Switch.vue'
import { ref, type PropType } from 'vue'
import { getCategoryTypeFromString, getCategoryImageFromStringAndAvatar } from '@/types/TypeMethods'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()

const props = defineProps({
  category: {
    type: String,
    required: true
  },
  avatarType: {
    type: String as PropType<AvatarTypes>,
    required: true
  },
  isToggled: {
    type: Boolean,
    required: true
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  }
})

const isCategoryToggled = ref(props.isToggled)

const toggleCategory = (isToggled: boolean) => {
  isCategoryToggled.value = isToggled
  emit('categoryToggled', isCategoryToggled)
}

const emit = defineEmits(['categoryToggled'])
</script>

<style scoped lang="scss">
@import './CategoryToggle.scss';
</style>
