export default [
  {
    path: '/game',
    name: 'games',
    redirect: { name: 'main-menu' },
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'main-menu',
        component: () => import('@/views/Game/MainMenu/MainMenu.vue'),
        meta: {
          requiresAcceptance: true
        }
      },
      {
        path: 'pregame',
        name: 'pregame',
        component: () => import('@/views/Game/Pregame/Pregame.vue'),
        meta: {
          requiresAcceptance: true
        }
      },
      {
        path: ':id',
        name: 'game',
        component: () => import('@/views/Game/Game/Game.vue'),
        meta: {
          requiresGameOwnership: true
        }
      },
      {
        path: 'disclaimer',
        name: 'disclaimer-game',
        component: () => import('@/views/Website/disclaimer/Disclaimer.vue'),
        props: { acceptance: true }
      }
    ]
  }
]
