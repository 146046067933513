export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Website/home/Home.vue')
  },
  {
    path: '/what-is-it',
    name: 'what',
    component: () => import('@/views/Website/what/What.vue')
  },
  {
    path: '/rules',
    name: 'rules',
    component: () => import('@/views/Website/rules/Rules.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/Website/about/About.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/Website/contact/Contact.vue')
  },
  {
    path: '/store',
    children: [
      {
        path: '',
        name: 'store',
        component: () => import('@/views/Website/store/Store.vue')
      },
      {
        path: 'bag',
        name: 'bag',
        component: () => import('@/views/Website/store/bag/Bag.vue')
      },
      {
        path: 'success',
        name: 'success',
        component: () => import('@/views/Website/store/confirmation-page/Success.vue')
      },
      {
        path: 'error',
        name: 'failure',
        component: () => import('@/views/Website/store/confirmation-page/Failure.vue')
      }
    ]
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    component: () => import('@/views/Website/disclaimer/Disclaimer.vue')
  },
  {
    path: '/parameters',
    name: 'parameters',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/Website/parameters/Parameters.vue')
  }
]
