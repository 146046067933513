<template>
  <div class="admin-nav-container">
    <i class="fi fi-sr-apps" @click="toggleMenu"></i>
    <SideMenu
      :isOpen="menuOpen"
      :isClosable="true"
      :leftMenu="true"
      @close="toggleMenu"
      class="slider"
    >
      <div class="settings-menu">
        <ul>
          <li>
            <router-link
              :to="{ name: 'packs' }"
              class="link"
              :class="{ active: $route.path.includes('/packs') }"
              >Lots de défis</router-link
            >
          </li>
          <ul class="sublinks" v-show="$route.path.includes('/packs')">
            <li><router-link :to="{ name: 'challenges' }" class="link">Défis</router-link></li>
            <li><router-link :to="{ name: 'categories' }" class="link">Catégories</router-link></li>
            <li><router-link :to="{ name: 'tags' }" class="link">Tags</router-link></li>
          </ul>
          <li><router-link :to="{ name: 'feedback' }" class="link">Feedback</router-link></li>
          <li><router-link :to="{ name: 'add-admin' }" class="link">Add admin</router-link></li>
          <li>
            <router-link :to="{ name: 'games' }" class="link">Aller à une partie</router-link>
          </li>
          <li><router-link :to="{ name: 'home' }" class="link">Aller au site web</router-link></li>
        </ul>
      </div>
    </SideMenu>
  </div>
</template>

<script setup lang="ts">
import SideMenu from '@/components/common/Global/side-menu/SideMenu.vue'
import { ref } from 'vue'
const menuOpen = ref(false)

function toggleMenu() {
  menuOpen.value = !menuOpen.value
}
</script>

<style scoped lang="scss">
@import './AdminNav.scss';
</style>
