import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateEmail,
  type User,
  type UserCredential
} from 'firebase/auth'

import { changeUserEmail, acceptDisclaimer } from '@/stores/users'

import { type Router } from 'vue-router'

interface AuthService {
  emailSignUp: (
    email: string,
    pwd: string,
    toast: any,
    router: Router,
    t: (key: string) => string
  ) => void
  emailSignIn: (
    email: string,
    pwd: string,
    toast: any,
    router: Router,
    t: (key: string) => string
  ) => void
  googleSignIn: (toast: any, router: Router, t: (key: string) => string) => void
  facebookSignIn: (toast: any, router: Router, t: (key: string) => string) => void
  getSignedInUser: () => Promise<User | null>
  sendResetPasswordEmail: (email: string, toast: any, t: (key: string) => string) => void
  updateEmail: (email: string, toast: any, t: (key: string) => string) => void
  acceptDisclaimer: (toast: any, t: (key: string) => string) => void
}

const authService: AuthService = {
  emailSignUp: (email, pwd, toast, router, t) => {
    createUserWithEmailAndPassword(getAuth(), email, pwd)
      .then((userCredential: UserCredential) => {
        sendEmailVerification(userCredential.user).then(() => {
          router.push({ name: 'games' })
        })
      })
      .catch((error) => {
        console.error(error.code, error.message)
        if (error.code === 'auth/email-already-in-use')
          toast.error(t('pages.auth.sign-in.email-already-used'))
        else toast.error(t('pages.auth.sign-in.generic-error'))
      })
  },
  emailSignIn: (email, pwd, toast, router, t) => {
    signInWithEmailAndPassword(getAuth(), email, pwd)
      .then((userCredential: UserCredential) => {
        router.push({ name: 'games' })
      })
      .catch((error) => {
        console.error(error.code, error.message)
        if (error.code === 'auth/invalid-email') toast.error(t('pages.auth.sign-in.invalid-email'))
        else toast.error(t('pages.auth.sign-in.generic-error'))
      })
  },
  googleSignIn: (toast, router, t) => {
    signInWithPopup(getAuth(), new GoogleAuthProvider())
      .then((userCredential: UserCredential) => {
        router.push({ name: 'games' })
      })
      .catch((error) => {
        console.error(error.code, error.message)
        toast.error(t('pages.auth.sign-in.generic-error'))
      })
  },
  facebookSignIn: (toast, router, t) => {
    signInWithPopup(getAuth(), new FacebookAuthProvider())
      .then((userCredential: UserCredential) => {
        router.push({ name: 'games' })
      })
      .catch((error) => {
        console.error(error.code, error.message)
        toast.error(t('pages.auth.sign-in.generic-error'))
      })
  },
  getSignedInUser: () => {
    return new Promise<User | null>((resolve) => {
      onAuthStateChanged(getAuth(), (user) => {
        if (!user) resolve(null)
        else resolve(user)
      })
    })
  },
  sendResetPasswordEmail: (email, toast, t) => {
    sendPasswordResetEmail(getAuth(), email)
      .then(() => {
        toast.success(t('pages.parameters.reset-password-email-sent'))
      })
      .catch((error) => {
        console.error(error.code, error.message)
        if (error.code === 'auth/invalid-email') toast.error(t('pages.auth.sign-in.invalid-email'))
        else toast.error(t('pages.auth.sign-in.generic-error'))
      })
  },
  updateEmail: (email, toast, t) => {
    const user = getAuth().currentUser

    if (!user) {
      toast.error(t('pages.parameters.messages.email-change-generic-error'))
      return
    }

    updateEmail(user, email)
      .then(() => {
        const user = getAuth().currentUser
        if (!user) {
          toast.error(t('pages.parameters.messages.email-change-generic-error'))
          return
        }
        sendEmailVerification(user).then(async () => {
          if (!user.email) {
            toast.error(t('pages.parameters.messages.email-change-generic-error'))
            return
          }
          changeUserEmail(user.uid, user.email).then(() => {
            toast.success(t('pages.parameters.email-updated'))
          })
        })
      })
      .catch((error) => {
        console.error(error.code, error.message)
        if (error.code === 'auth/invalid-email') toast.error(t('pages.auth.sign-in.invalid-email'))
        if (error.code === 'auth/email-already-in-use')
          toast.error(t('pages.auth.sign-in.email-already-used'))
        if (error.code === 'auth/requires-recent-login')
          toast.error(t('pages.parameters.requires-recent-login'))
        else toast.error(t('pages.parameters.messages.email-change-generic-error'))
      })
  },
  acceptDisclaimer: (toast, t) => {
    const user = getAuth().currentUser

    if (!user) {
      toast.error(t('pages.disclaimer.generic-error'))
      return
    }

    acceptDisclaimer(user.uid).catch((error) => {
      console.error(error.code, error.message)
      toast.error(t('pages.disclaimer.generic-error'))
    })
  }
}

export default authService
