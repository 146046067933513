import { defineStore } from 'pinia'
import { type Challenge, type Game } from '@/types/Types'
import { getGame } from '@/stores/game'
import { createGame, updateGame, removeGameFromOwner, deleteGame } from '@/stores/game'
import { assignGameToUser } from '@/stores/users'
import { useAuthStore } from '@/stores/authStore'
import { useRouter } from 'vue-router'
import { BASE_SCORE_NEEDED_BY_TEAMMATE } from '@/types/Constants'
import { type Router } from 'vue-router'

export const useGameStore = defineStore('game', {
  state: (): GameState => ({
    game: undefined,
    challenges: undefined,
    currentChallenge: undefined
  }),
  persist: true,

  getters: {
    teamScore: (state: GameState) => {
      if (!state.game) return 0
      return calculateTeamScore(state.game.players)
    }
  },

  actions: {
    fetchGame: async (state: GameState, gameId: string, router: Router) => {
      state.game = await getGame(gameId, router)
    },
    startGame: async (state: GameState, game: Game, router: Router) => {
      const authStore = useAuthStore()

      const gameId = await createGame(game)
      if (!authStore.firebaseUser) return gameId
      await assignGameToUser(authStore.firebaseUser.uid, gameId)
      state.game = await getGame(gameId, router)
      return gameId
    },
    progressGame: async (state: GameState, router: Router) => {
      if (!state.game || !state.game.id) return
      await updateGame(state.game)
      state.game = await getGame(state.game.id, router)
    },
    endGame: async (state: GameState) => {
      if (!state.game || !state.game.id) return

      await removeGameFromOwner(state.game)
      await deleteGame(state.game.id)
    },
    checkIfTeamWon: (state: GameState) => {
      if (!state.game) return false
      const neededscore = state.game.players.length * BASE_SCORE_NEEDED_BY_TEAMMATE

      return calculateTeamScore(state.game.players) >= neededscore
    },
    getCurrentChallenge: (state: GameState) => {
      if (!state.challenges || !state.game) return
      state.currentChallenge = state.challenges[state.game.currentChallengeIndex]
    }
  }
})

interface GameState {
  game: Game | undefined
  challenges: Challenge[] | undefined
  currentChallenge: Challenge | undefined
}

function calculateTeamScore(players: any) {
  return players.reduce((acc: number, player: any) => acc + player.score, 0)
}
