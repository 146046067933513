import { getStorage, ref, deleteObject } from 'firebase/storage'

export function removeAccents(input: string): string {
  return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export async function deleteImageFromBucket(imageURL: string) {
  const storage = getStorage()
  const imageRef = ref(storage, imageURL)
  await deleteObject(imageRef)
}

export function isMobileDevice(): boolean {
  return /Mobi|Android/i.test(navigator.userAgent)
}
