<template>
  <header ref="headerRef">
    <nav aria-label="Main Navigation">
      <div class="desktop-nav">
        <div class="branding">
          <router-link :to="{ name: 'home' }" class="no-active" aria-label="Home"
            ><img src="/Logo-App.svg" alt="Logo Allez! Allez!"
          /></router-link>
        </div>
      </div>
      <div ref="icon" class="icon">
        <div class="hamburger-lines" @click="toggleMenu()" aria-label="Toggle  Menu">
          <span class="line1"></span>
          <span class="line2"></span>
          <span class="line3"></span>
        </div>
      </div>
    </nav>

    <SideMenu :isOpen="sideMenuOpen" @close="toggleMenu" class="slider">
      <div class="options">
        <ul>
          <li>
            <router-link
              class="no-border"
              :to="{ name: 'main-menu' }"
              aria-label="Main menu"
              @click="close"
              >{{ $t('game.nav.main-menu') }}</router-link
            >
          </li>

          <li>
            <router-link
              class="no-border"
              :to="{ name: 'home' }"
              aria-label="Home"
              @click="close"
              >{{ $t('game.nav.quit') }}</router-link
            >
          </li>

          <li>
            <div class="params" aria-label="Rules" @click="openRules">
              {{ $t('pages.rules.title') }}
            </div>
          </li>
        </ul>
      </div>
      <div class="footer">
        <div class="params" aria-label="Parameters" @click="openParameters">
          {{ $t('pages.parameters.title') }}
        </div>
        <div class="params" aria-label="Parameters" @click="toggleFeedbackModal">
          {{ $t('game.nav.feedback') }}
        </div>
        <button type="button" @click="handleSignOut" class="primary full-width">
          {{ $t('nav.logout') }}
        </button>
      </div>
    </SideMenu>

    <Modal
      :fullScreen="isMobileDevice()"
      :isOpen="feedbackModalOpen"
      @close="feedbackModalOpen = false"
    >
      <FeedbackForm
        :game="gameStore.game"
        :challenge="gameStore.currentChallenge"
        @close="feedbackModalOpen = false"
      />
    </Modal>

    <Modal :full-screen="true" :isOpen="modalOpen" @close="closeModal">
      <Parameters :noBackground="true" v-if="parametersOpen" class="modal-content" />
      <Rules :inModal="true" v-if="rulesOpen" class="modal-content" />
    </Modal>
  </header>
</template>

<script setup lang="ts">
import SideMenu from '@/components/common/Global/side-menu/SideMenu.vue'
import Modal from '@/components/common/Global/modal/Modal.vue'
import Parameters from '@/views/Website/parameters/Parameters.vue'
import Rules from '@/views/Website/rules/Rules.vue'
import FeedbackForm from '@/components/Game/feedback/FeedbackForm.vue'

import { onMounted, onUnmounted, ref } from 'vue'
import { useToast } from 'vue-toastification'
import { getAuth, signOut } from 'firebase/auth'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useGameStore } from '@/stores/gameStore'
import { isMobileDevice } from '@/stores/methods'

const gameStore = useGameStore()
const toast = useToast()
const router = useRouter()
const { t } = useI18n()
const isMobile = ref(false)
const sideMenuOpen = ref(false)
const headerRef = ref<HTMLElement | undefined>()
const modalOpen = ref(false)
const parametersOpen = ref(false)
const rulesOpen = ref(false)
const feedbackModalOpen = ref(false)

function handleSignOut() {
  signOut(getAuth())
    .then(() => {
      toast.success(t('nav.messages.logout-success'))
      sideMenuOpen.value = false
      router.push({ name: 'home' })
    })
    .catch((error) => {
      toast.error(t('nav-logout-error'))
    })
}

function toggleMenu() {
  sideMenuOpen.value = !sideMenuOpen.value
}

function toggleFeedbackModal() {
  feedbackModalOpen.value = !feedbackModalOpen.value
}

function openParameters() {
  parametersOpen.value = true
  modalOpen.value = true
}

function openRules() {
  rulesOpen.value = true
  modalOpen.value = true
}

function closeModal() {
  modalOpen.value = false
  parametersOpen.value = false
  rulesOpen.value = false
}

function checkScreenSize() {
  if (window.innerWidth < 990) {
    isMobile.value = true
    return
  }
  isMobile.value = false
  return
}

function close() {
  sideMenuOpen.value = false
}

onMounted(async () => {
  checkScreenSize()
  window.addEventListener('resize', checkScreenSize)
})

onUnmounted(() => {
  window.removeEventListener('resize', checkScreenSize)
})
</script>

<style scoped lang="scss">
@import './GameNavbar.scss';
</style>
