<template>
  <div class="social">
    <a
      class="no-active"
      aria-label="Facebook"
      href="https://www.facebook.com/profile.php?id=100095260045646"
      target="_blank"
    >
      <i class="fi fi-brands-facebook"></i>
    </a>
    <a
      class="no-active logo"
      aria-label="Instagram"
      href="https://www.instagram.com/allez.allez.app/"
      target="_blank"
    >
      <i class="fi fi-brands-instagram"></i>
    </a>
    <a
      class="no-active logo"
      aria-label="Youtube"
      href="https://www.youtube.com/@AllezAllezApp"
      target="_blank"
    >
      <i class="fi fi-brands-youtube"></i>
    </a>
  </div>
</template>

<style scoped lang="scss">
@import './socials.scss';
</style>
