<template>
  <div :id="id">
    <label v-if="title !== undefined" :for="title"
      >{{ title }}<span v-if="!validation.required"> ({{ $t('global.optional') }})</span></label
    >
    <file-pond
      ref="pond"
      :server="myServer"
      :allow-multiple="allowMultiple"
      :max-files="maxFiles"
      :files="files"
      labelIdle='Glisser et déposer un fichier ou <span class="filepond--label-action">Parcourir</span>'
      labelInvalidField="Le champ contient des fichiers invalides"
      labelFileWaitingForSize="En attente de la taille du fichier"
      labelFileSizeNotAvailable="Taille non disponible"
      labelFileLoading="Chargement"
      labelFileLoadError="Erreur lors du chargement"
      labelFileProcessing="Téléversement"
      labelFileProcessingComplete="Téléversement terminé"
      labelFileProcessingAborted="Téléversement annulé"
      labelFileProcessingError="Erreur lors du téléversement"
      labelFileProcessingRevertError="Erreur lors de la restauration"
      labelFileRemoveError="Erreur lors de la suppression"
      labelTapToCancel="cliquer pour annuler"
      labelTapToRetry="cliquer pour réessayer"
      labelTapToUndo="cliquer pour annuler"
      labelButtonRemoveItem="Supprimer"
      labelButtonAbortItemLoad="Annuler"
      labelButtonRetryItemLoad="Réessayer"
      labelButtonAbortItemProcessing="Annuler"
      labelButtonUndoItemProcessing="Revenie en arrière"
      labelButtonRetryItemProcessing="Réessayer"
      labelButtonProcessItem="Téléverser"
    />
    <p v-if="!status.valid">
      {{ status.message }}
    </p>
  </div>
</template>

<script setup lang="ts">
import vueFilePond from 'vue-filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import 'filepond/dist/filepond.min.css'

import type { Status, ValidationParams } from '@/types/Types'

import { computed } from 'vue'

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: false,
    default: undefined
  },
  allowMultiple: {
    type: Boolean,
    required: false,
    default: false
  },
  maxFiles: {
    type: Number,
    required: false,
    default: null
  },
  files: {
    type: Array,
    required: false,
    default: []
  },
  status: {
    type: Object as () => Status,
    required: false,
    default: {
      valid: true,
      message: ''
    }
  },
  validation: {
    type: Object as () => ValidationParams,
    required: false,
    default: {
      required: false,
      minLength: undefined,
      maxLength: undefined,
      regex: undefined
    }
  }
})

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void
}>()

function updateValue(imgURL: string) {
  emit('update:modelValue', imgURL)
}

const classes = computed(() => ({
  // Les classes sont gérées ici (voir bouton)
  'border-danger': !props.status.valid && props.status.touched,
  'border-success': props.status.valid && props.status.touched
}))

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)

import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
// Get a reference to the storage service
const storage = getStorage()

const myServer = {
  // @ts-ignore
  process: (fieldName, file, metadata, load, error, progress) => {
    // Create a storage reference for the file
    const fileRef = ref(storage, `Images/${file.name}`)

    // Upload the file and metadata
    const uploadTask = uploadBytesResumable(fileRef, file)

    // Listen for state changes, errors, and completion of the upload
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Calculate the upload progress
        const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        progress(uploadProgress)
      },
      (uploadError) => {
        error(uploadError)
      },
      async () => {
        // Get the download URL when the upload is completed
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
        load(downloadURL)
        updateValue(downloadURL)
      }
    )
  },
  // @ts-ignore
  load: (source, load) => {
    fetch(source)
      .then((res) => res.blob())
      .then(load)
  }
}
</script>

<style lang="scss" scoped>
@import './FileUpload.scss';
</style>
