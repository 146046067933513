import { AvatarTypes } from '@/types/Enums'

export const EMAIL_REGEX = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
export const EMAIL_REGEX_MESSAGE = 'global.form.validation.regex.email'
export const PASSWORD_REGEX = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/
)
export const PASSWORD_REGEX_MESSAGE = 'global.form.validation.regex.password'
export const PHONE_REGEX = new RegExp(/^\d{3}-\d{3}-\d{4}$/)
export const PHONE_REGEX_MESSAGE = 'global.form.validation.regex.phone'

export const CHALLENGES_PER_PLAYER = 5

export const BASIC_SCORE_MULTIPLIER = 100
export const BASIC_SCORE_MALUS = 25
export const BASE_SCORE_NEEDED_BY_TEAMMATE = (BASIC_SCORE_MULTIPLIER * CHALLENGES_PER_PLAYER) / 2

export const SOCIAL_MAX_PER_PERSON = 1
export const FORCE_MAX_PER_PERSON = 2
export const OTHER_MAX_PER_PERSON = 3

export const HOW_TO_INSTALL_LINK_EN = 'https://www.youtube.com/shorts/f4YBHDBdbc8'
export const HOW_TO_INSTALL_LINK_FR = 'https://www.youtube.com/shorts/BnB7sW2cRh0'

export const TEAM_BOULDER_PACK_ID = 'DhtRZ4Zb3JOZCABd1ElW'

export const BASE_AVATARS = [
  AvatarTypes.GREEN_YELLOW,
  AvatarTypes.BLUE_RED,
  AvatarTypes.PURPLE_YELLOW,
  AvatarTypes.BLUE_YELLOW,
  AvatarTypes.RED_BLUE,
  AvatarTypes.ORANGE_GREEN
]

export const TEAM_BOULDER_AVATARS = [AvatarTypes.DARK_BLUE_YELLOW, AvatarTypes.GREEN_PINK]
