<template>
  <form class="feedback-container">
    <h2>{{ $t('game.game.feedback.title') }}</h2>
    <p>
      {{ $t('game.game.feedback.text-1') }}
    </p>
    <p class="bold">{{ $t('game.game.feedback.text-3') }}</p>

    <Input
      id="subject"
      class="input"
      :title="$t('global.form.subject')"
      :value="subject"
      @update:modelValue="updateSubject"
      :validation="{ required: true }"
    />
    <TextArea
      id="message"
      class="input"
      :title="$t('global.form.your-message')"
      :rows="10"
      :value="message"
      @update:modelValue="updateMessage"
      :validation="{ required: true }"
    />
    <em>{{ $t('game.game.feedback.text-2') }}</em>
    <button
      class="secondary"
      :disabled="!formIsValid"
      :class="{ disabled: !formIsValid }"
      @click.prevent="send"
    >
      {{ $t('global.form.send') }}
    </button>
  </form>
</template>

<script setup lang="ts">
import Input from '@/components/common/form/input/Input.vue'
import TextArea from '@/components/common/form/textarea/TextArea.vue'
import mailService from '@/stores/mail'

import type { Status, Game, Challenge } from '@/types/Types'

import { useI18n } from 'vue-i18n'
import { ref, computed } from 'vue'
import { useToast } from 'vue-toastification'

const props = defineProps<{
  game?: Game
  challenge?: Challenge
}>()

const emits = defineEmits(['close'])

const { t } = useI18n()
const toast = useToast()
const subject = ref('')
const subjectStatus = ref<Status>({ valid: false, message: '' })
const message = ref('')
const messageStatus = ref<Status>({ valid: false, message: '' })

function updateSubject(value: string, status: Status) {
  subject.value = value
  subjectStatus.value = status
}

function updateMessage(value: string, status: Status) {
  message.value = value
  messageStatus.value = status
}

const formIsValid = computed(() => subjectStatus.value.valid && messageStatus.value.valid)

function send() {
  if (!formIsValid.value) return

  mailService.sendFeedback(subject.value, message.value, props.game, props.challenge, toast, t)
  clearForm()
  emits('close')
}

function clearForm() {
  subject.value = ''
  subjectStatus.value = { valid: false, message: '' }
  message.value = ''
  messageStatus.value = { valid: false, message: '' }
}
</script>

<style scoped lang="scss">
@import './FeedbackForm.scss';
</style>
